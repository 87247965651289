export const deviceTypes = {
  CT: 'ct',
  MRI: 'mri',
  MAM: 'mam',
  RWS: 'rws',
};

export const scannerTypes = {
  CT: 'ct',
  MRI: 'mri',
  MAM: 'mam',
};

export const deviceTitles = {
  CT: 'Computed Tomography Scanner',
  MRI: 'Magnetic Resonance Imaging Scanner',
  MAM: 'Mammography Scanner',
  RWS: 'Reading Workstation for Mammography',
};

export const idFields = {
  CT: 'apNumber',
  MRI: 'apNumber',
  MAM: 'apNumber',
  RWS: 'serialNumber',
};

export const idTitles = {
  CT: 'CTAP',
  MRI: 'MRAP',
  MAM: 'MAP',
  RWS: 'Serial No.',
};

export const ctFields = {
  water: 'water',
  axialNoise: 'axialNoise',
  helicalNoise: 'helicalNoise',
};

export const ctFieldTitles = {
  water: 'Water HU',
  axialNoise: 'Axial HU',
  helicalNoise: 'Helical HU',
};

export const mriFields = {
  centerFreq: 'centerFreq',
  txGainAtten: 'txGainAtten',
  geoAccuracyHf: 'geoAccuracyHf',
  geoAccuracyAp: 'geoAccuracyAp',
  geoAccuracyRl: 'geoAccuracyRl',
};

export const mriFieldTitles = {
  centerFreq: 'Center Frequency Hz',
  txGainAtten: 'TX Gain Attenuation dB',
  geoAccuracyHf: 'Geometric Accuracy H/F',
  geoAccuracyAp: 'Geometric Accuracy A/P',
  geoAccuracyRl: 'Geometric Accuracy R/L',
};
