import * as React from 'react';
import { Box, Typography, FormControl, Button, MenuItem, Paper, FormLabel, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ErrorMessage, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';
import { RadSelect, RadTextInput, RadCheckbox } from 'presentational/Inputs';
import { useOrganizationCtx } from 'context/OrganizationContext';
import { deviceTypes, deviceTitles, scannerTypes } from 'types/device';
import { getIdFieldAndTitleForType } from 'utils/device.util';
import AdditionalSettingsMRI from './AdditionalSettingsMRI';
import { useModalCtx } from 'context/ModalContext';
import { useDeviceCtx } from 'context/DeviceContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useHistory } from 'react-router-dom';

const styles = {};

function ModifyDevice(props) {
  const { selectedDevice } = props;
  const { hideModal } = useModalCtx();
  const history = useHistory();
  const { facilities } = useOrganizationCtx();
  const { currUser } = useCurrUserCtx();
  const { getAccessTokenSilently } = useAuth0();
  const { unfilteredDevices, updateDevices } = useDeviceCtx();
  const { enqueueSnackbar } = useSnackbar();

  let takenNames = unfilteredDevices.map((device) => device.name);
  if (selectedDevice) {
    takenNames = takenNames.filter((name) => name !== selectedDevice.name);
  }

  const isUpdate = !!selectedDevice;
  const action = isUpdate ? 'Update' : 'Add';

  const getApInput = (deviceType) => {
    const [idField, idTitle] = getIdFieldAndTitleForType(deviceType);
    if (idField === 'apNumber') {
      return (
        <FormControl>
          <FormLabel sx={{ fontSize: 12 }}>{idTitle}*</FormLabel>
          <RadTextInput name="apNumber" type="text" />
        </FormControl>
      );
    }
    return null;
  };

  const ctFields = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>Water Range (HU)</FormLabel>
        <RadTextInput name="waterRange" type="number" />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>Noise SD Range (%)</FormLabel>
        <RadTextInput name="noiseRange" type="number" />
      </FormControl>
    </Box>
  );

  const mamFields = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControl>
          <FormLabel sx={{ fontSize: 12 }}>CNR Baseline</FormLabel>
          <RadTextInput name="cnrBaseline" type="number" />
        </FormControl>
        <FormControl>
          <RadCheckbox name="is3dSet">
            <Typography sx={{ fontSize: 12 }}>Enable 3D</Typography>
          </RadCheckbox>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControl>
          <FormLabel sx={{ fontSize: 12 }}>Phantom Serial Number</FormLabel>
          <RadTextInput name="phantomSerialNumber" type="text" />
        </FormControl>
      </Box>
    </>
  );

  const rwsFields = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <FormControl>
        <RadCheckbox name="dailySectra">
          <Typography sx={{ fontSize: 12 }}>Perform Daily Sectra</Typography>
        </RadCheckbox>
      </FormControl>
    </Box>
  );

  const getAdditionalSettings = (values) => {
    const deviceType = values.type;
    switch (deviceType) {
      case deviceTypes.MAM:
        return mamFields;
      case deviceTypes.CT:
        return ctFields;
      case deviceTypes.MRI:
        return <AdditionalSettingsMRI heliumSet={values.heliumSet} />;
      case deviceTypes.RWS:
        return rwsFields;
      default:
        throw Error(`Unknown device type: ${deviceType}`);
    }
  };

  const initialValues = isUpdate
    ? selectedDevice
    : {
        type: deviceTypes.MAM,
        name: '',
        apNumber: '',
        manufacturer: '',
        modelNumber: '',
        serialNumber: '',
        facilityId: '',
        waterRange: null,
        noiseRange: null,
        fieldStrength: null,
        heliumSet: false,
        heAlertLevel: null,
        coldHeadTrack: false,
        dailySectra: false,
        emailEngineers: false,
        cnrBaseline: null,
        phantomSize: null,
        phantomSerialNumber: null,
        is3dSet: false,
      };

  const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    name: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .notOneOf(takenNames, 'This name is already in use')
      .required('Required'),
    apNumber: Yup.string()
      .nullable()
      .when('type', {
        is: (type) => Object.values(scannerTypes).includes(type),
        then: (schema) => schema.required('Required'),
      }),
    manufacturer: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
    modelNumber: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
    serialNumber: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
    facilityId: Yup.string().required('Required'),
    waterRange: Yup.number()
      .nullable()
      .when('type', {
        is: deviceTypes.CT,
        then: (schema) => schema.required('Required'),
      }),
    noiseRange: Yup.number()
      .nullable()
      .when('type', {
        is: deviceTypes.CT,
        then: (schema) => schema.required('Required'),
      }),
    fieldStrength: Yup.number()
      .nullable()
      .when('type', {
        is: deviceTypes.MRI,
        then: (schema) => schema.required('Required'),
      }),
    heliumSet: Yup.bool().nullable(),
    heAlertLevel: Yup.number()
      .nullable()
      .when('heliumSet', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
    coldHeadTrack: Yup.bool().nullable(),
    dailySectra: Yup.bool().nullable(),
    emailEngineers: Yup.bool().nullable(),
    cnrBaseline: Yup.number()
      .nullable()
      .when('type', {
        is: deviceTypes.MAM,
        then: (schema) => schema.required('Required'),
      }),
    phantomSize: Yup.string()
      .nullable()
      .when('type', {
        is: deviceTypes.MRI,
        then: (schema) => schema.required('Required'),
      }),
    phantomSerialNumber: Yup.string()
      .nullable()
      .when('type', {
        is: deviceTypes.MAM,
        then: (schema) => schema.required('Required'),
      }),
    is3dSet: Yup.bool().nullable(),
  });

  const handleSubmit = async (values, setSubmitting) => {
    let newDevice = Object.assign(values);
    const selFacility = facilities.find((a) => a._id === newDevice.facilityId);
    newDevice.facilityName = selFacility.name;

    // Submit device data to DB
    const accessToken = await getAccessTokenSilently();

    if (action.toLowerCase() === 'update') {
      newDevice = await radqcApi('put', `/devices/${selectedDevice?._id}`, newDevice, { accessToken });
      enqueueSnackbar(`Updated ${selectedDevice.name}`);
    } else {
      newDevice.accountId = currUser.selectedAccount;
      newDevice.users = [currUser._id];
      newDevice = await radqcApi('post', '/devices', newDevice, { accessToken });
      enqueueSnackbar(`Created new device: ${newDevice.name}`);
    }
    updateDevices();
    setSubmitting(false);

    // Go to the dashboard for the new/modified device
    if (action.toLowerCase() === 'add') {
      history.push(`/devices/${newDevice._id}`);
    }
  };

  return (
    <Box component="div" sx={{ width: 400 }}>
      <Typography sx={{ fontSize: 20 }}> {action} Device</Typography>
      <Typography sx={{ color: 'grey', fontSize: 12 }}> Note that some fields are required.</Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
          hideModal();
        }}
      >
        {({ values, isValid }) => {
          return (
            <Form>
              <Box sx={{ width: 200, marginTop: '12px' }}>
                <FormLabel sx={{ fontSize: 12 }}>Facility</FormLabel>
                <RadSelect name="facilityId">
                  {facilities?.map((facility) => (
                    <MenuItem key={facility._id} value={facility._id}>
                      {facility.name}
                    </MenuItem>
                  ))}
                </RadSelect>
                <ErrorMessage name="facilityId" />
              </Box>
              <Divider sx={{ marginY: 3 }} />
              <Typography sx={{ fontSize: 16, mb: 3 }}>{deviceTitles[values?.type?.toUpperCase()]}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: 12 }}>Device Type*</FormLabel>
                  <RadSelect name="type" disabled={action === 'Update'}>
                    {Object.values(deviceTypes).map((deviceType) => (
                      <MenuItem key={deviceType} value={deviceType}>
                        {deviceType?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </RadSelect>
                  <ErrorMessage name="type" />
                </FormControl>
                {getApInput(values.type)}
              </Box>

              {/**----name and manufacturer--- */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: 12 }}>Name*</FormLabel>
                  <RadTextInput name="name" type="text" />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ fontSize: 12 }}>Manufacturer*</FormLabel>
                  <RadTextInput name="manufacturer" type="text" />
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: 12 }}>Model Number*</FormLabel>
                  <RadTextInput name="modelNumber" type="text" />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ fontSize: 12 }}>Serial Number*</FormLabel>
                  <RadTextInput name="serialNumber" type="text" />
                </FormControl>
              </Box>

              <Typography
                sx={{ fontSize: 16, my: 3 }}
              >{`${values?.type?.toUpperCase()} Additional Settings`}</Typography>
              {getAdditionalSettings(values)}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                <FormControl>
                  <Button sx={{ marginRight: '16px' }} color="secondary" onClick={() => hideModal()}>
                    Cancel
                  </Button>
                </FormControl>
                <FormControl>
                  <Button disabled={!isValid} variant="outlined" color="primary" type="submit">
                    {action} Device
                  </Button>
                </FormControl>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

ModifyDevice.propTypes = {
  selectedDevice: PropTypes.object,
};

ModifyDevice.defaultProps = {
  selectedDevice: null,
};

export default withStyles(styles)(ModifyDevice);
