import moment from 'moment';
import { findKey, get, cloneDeep } from 'lodash';
import { ctFields, mriFields, ctFieldTitles, mriFieldTitles } from 'types/device';

export const defaultDatasetConfig = {
  fill: false,
  lineTension: 0.1,
  backgroundColor: 'rgba(75,192,192,0.4)',
  borderColor: 'rgba(0,94,212,1)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(7,117,255,1)',
  pointBackgroundColor: 'rgba(7,117,255,1)',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(7,117,255,1)',
  pointHoverBorderColor: 'rgba(26,35,126,0.5)',
  pointHoverBorderWidth: 2,
  pointRadius: 1.5,
  pointHitRadius: 10,
};

const maxDatasetConfig = {
  backgroundColor: 'rgba(75,192,192,0.2)',
  borderColor: 'rgba(75,192,192,0.3)',
  pointBorderColor: 'rgba(75,192,192,0.3)',
  pointBackgroundColor: 'rgba(75,192,192,0.3)',
  pointHoverBackgroundColor: 'rgba(75,192,192,0.8)',
  pointHoverBorderColor: 'rgba(75,192,192,0.5)',
};

const minDatasetConfig = {
  borderColor: 'rgba(75,192,192,0.3)',
  pointBorderColor: 'rgba(75,192,192,0.3)',
  pointBackgroundColor: 'rgba(75,192,192,0.3)',
  pointHoverBackgroundColor: 'rgba(75,192,192,0.8)',
  pointHoverBorderColor: 'rgba(75,192,192,0.5)',
};

const outValuesDatasetConfig = {
  backgroundColor: 'rgba(221,51,51,0.5)',
  borderColor: 'rgba(221,51,51,1)',
  pointBorderColor: 'rgba(221,51,51,1)',
  pointBackgroundColor: 'rgba(221,51,51,1)',
  pointHoverBackgroundColor: 'rgba(221,51,51,1)',
  pointHoverBorderColor: 'rgba(221,51,51,0.5)',
};

export const enumTimePeriodOptions = {
  '10 days': { unit: 'day', value: 10 },
  '20 days': { unit: 'day', value: 20 },
  '1 month': { unit: 'month', value: 1 },
  '2 months': { unit: 'month', value: 2 },
  '6 months': { unit: 'month', value: 6 },
  '1 year': { unit: 'year', value: 1 },
};

export const timePeriodToStr = (timePeriodObj) => findKey(enumTimePeriodOptions, timePeriodObj);

const isDateValidForData = (date, filter) => {
  const tempDate = date.clone();
  const isOddDay = !!(tempDate.format('D') % 2);
  return (
    !filter || (filter && filter.includes('axial') && isOddDay) || (filter && filter.includes('helical') && !isOddDay)
  );
};

const enumerateTimeBetweenDates = (startDate, endDate, filter) => {
  const currDate = startDate.clone();
  const dates = [];

  while (currDate.isSameOrBefore(endDate, 'day')) {
    if (isDateValidForData(currDate, filter)) {
      dates.push(currDate.clone());
    }
    currDate.add(1, 'day');
  }
  return dates;
};

const adjustStartDate = (timePeriod, filter) => {
  const subtractValue = timePeriod.unit === 'day' ? timePeriod.value - 1 : timePeriod.value;
  let startDate = moment().subtract(subtractValue, timePeriod.unit);

  if (!filter) return startDate;

  if (
    (filter.includes('axial') && startDate.format('D') % 2 === 0) ||
    (filter.includes('helical') && startDate.format('D') % 2 !== 0)
  ) {
    startDate = startDate.subtract(1, 'day');
  }

  return startDate;
};

const resolveFilter = (filter, entry) => {
  if (!filter) return true;
  // try the following with [field, value]
  const field = filter.split('=')[0];
  const value = filter.split('=')[1];
  return entry[field] === value;
};

export const isCtProperty = (property) => Object.keys(ctFields).includes(property);

const getCtPropertyData = (entryData, startDate, endDate, rawTrackingProps, filter) => {
  // TODO: add case for properties not on daily entries

  // replace '_' with '.' in trackingProp names
  const trackingProps = rawTrackingProps.map((prop) => prop.replace('_', '.'));
  const refinedData = [];
  entryData.forEach((monthlyEntry) => {
    monthlyEntry.dailyEntries.sort((a, b) => a.day - b.day);
    monthlyEntry.dailyEntries.forEach((dailyEntry) => {
      const dailyEntryDate = moment(monthlyEntry.date);
      dailyEntryDate.set('date', dailyEntry.day);
      if (
        dailyEntryDate.isSameOrAfter(startDate, 'day') &&
        dailyEntryDate.isSameOrBefore(endDate, 'day') &&
        resolveFilter(filter, dailyEntry)
      ) {
        const dataPoint = { date: dailyEntryDate, info: {} };
        trackingProps.forEach((prop) => {
          dataPoint.info[prop.replace('.', '_')] = get(dailyEntry, prop);
        });
        refinedData.push(dataPoint);
      }
    });
  });

  return refinedData;
};

const getMriPropertyData = (fieldName, entryData) => {
  // replace '_' with '.' in trackingProp names
  const refinedData = [];
  entryData.forEach((entry) => {
    let info = {};
    switch (fieldName) {
      case mriFields.centerFreq:
        info = {
          centerFreq_value: entry.centerFreq,
          centerFreq_max: entry.cfMax,
          centerFreq_min: entry.cfMin,
        };
        break;
      case mriFields.txGainAtten:
        info = {
          txGainAtten_value: entry.txGainAtten,
          txGainAtten_max: entry.tgMax,
          txGainAtten_min: entry.tgMin,
        };
        break;
      case mriFields.geoAccuracyHf:
        info = {
          geoAccuracyHf_value: entry.geoAccuracyHf,
          geoAccuracyHf_max: 150,
          geoAccuracyHf_min: 146,
        };
        break;
      case mriFields.geoAccuracyAp:
        info = {
          geoAccuracyAp_value: entry.geoAccuracyAp,
          geoAccuracyAp_max: 192,
          geoAccuracyAp_min: 188,
        };
        break;
      case mriFields.geoAccuracyRl:
        info = {
          geoAccuracyRl_value: entry.geoAccuracyRl,
          geoAccuracyRl_max: 192,
          geoAccuracyRl_min: 188,
        };
        break;
      default:
        throw Error(`Unrecognized field: ${fieldName}`);
    }
    const dataPoint = { date: entry.date, info };
    refinedData.push(dataPoint);
  });

  return refinedData;
};

const splitOutofRange = (data, rangeProp) => {
  const splitData = data.map((dataPt) => {
    const newPt = cloneDeep(dataPt);
    const value = newPt.info[`${rangeProp}_value`];
    newPt.info[`${rangeProp}_over`] = value > newPt.info[`${rangeProp}_max`] ? value : null;
    newPt.info[`${rangeProp}_below`] = value < newPt.info[`${rangeProp}_min`] ? value : null;
    return newPt;
  });
  return splitData;
};

export const transformCtData = (rawData, timePeriod, trackingProps, filter) => {
  // TODO: Error checking on all inputs
  if (!rawData || !Array.isArray(rawData)) {
    throw Error(`Raw data must be an array, received: ${rawData}`);
  }

  const today = moment();
  const startDate = adjustStartDate(timePeriod, filter);

  // Get Labels
  const labels = enumerateTimeBetweenDates(startDate, today, filter).map((date) => date.format('MMM D, YYYY'));

  // Pull the desired property data from the raw data
  const propData = getCtPropertyData(rawData, startDate, today, trackingProps, filter);

  // Split value data into in range values, over values and below values if max and/or min specified
  let preppedData = propData;
  let rangeProp = trackingProps.find((a) => a.includes('max') || a.includes('min'));
  if (rangeProp) {
    [rangeProp] = rangeProp.split('_');
    preppedData = splitOutofRange(propData, rangeProp);
  }

  // Generate the dataset for the parameters specified
  const cookedData = trackingProps.reduce((o, key) => ({ ...o, [key]: [] }), {});

  const addDataPoint = (dataObj, dataPtToAdd) => {
    trackingProps.forEach((dataSetName) => {
      get(dataObj, dataSetName).push(dataPtToAdd ? dataPtToAdd[dataSetName] : null);
    });
  };

  const timeCounter = startDate.clone();
  let dataCounter = 0;
  while (timeCounter.isSameOrBefore(today) && dataCounter < preppedData.length && preppedData.length !== 0) {
    if (timeCounter.isSame(preppedData[dataCounter].date, 'day')) {
      // Data exists for this date
      addDataPoint(cookedData, preppedData[dataCounter].info);
      dataCounter += 1;
    } else if (isDateValidForData(timeCounter, filter)) {
      // This is a valid date for data but it is missing, add a null data point
      addDataPoint(cookedData, null);
    }
    timeCounter.add(1, 'day');
  }
  return [labels, cookedData];
};

const transformMriData = (trackingProp, rawData, trackingProps) => {
  // TODO: Error checking on all inputs
  if (!rawData || !Array.isArray(rawData)) {
    throw Error(`Raw data must be an array, received: ${rawData}`);
  }

  // Get Labels
  const labels = rawData.map((entry) => moment(entry.date).format('MMM D, YYYY'));

  // Pull the desired property data from the raw data
  const propData = getMriPropertyData(trackingProp, rawData);

  // Split value data into in range values, over values and below values if max and/or min specified
  let preppedData = propData;
  let rangeProp = trackingProps.find((a) => a.includes('max') || a.includes('min'));
  if (rangeProp) {
    [rangeProp] = rangeProp.split('_');
    preppedData = splitOutofRange(propData, rangeProp);
  }

  // Generate the dataset for the parameters specified
  const cookedData = trackingProps.reduce((o, key) => ({ ...o, [key]: [] }), {});
  preppedData.forEach((entry) => {
    Object.keys(cookedData).forEach((field) => cookedData[field].push(entry.info[field]));
  });

  return [labels, cookedData];
};

export const transformData = (trackingProp, rawData, timePeriod, trackingProps, filter) =>
  isCtProperty(trackingProp)
    ? transformCtData(rawData, timePeriod, trackingProps, filter)
    : transformMriData(trackingProp, rawData, trackingProps);

export const generateChartConfig = (oldConfig, newValues) => {
  const newChartConfig = cloneDeep(oldConfig);
  Object.keys(newChartConfig).forEach((property) => {
    if (newValues[property] != null) {
      newChartConfig[property] = newValues[property];
    }
  });
  newChartConfig.timePeriod = enumTimePeriodOptions[newChartConfig.timePeriod];
  newChartConfig.filter = '';
  newChartConfig.datasetConfigs = {};
  let propName;
  let label;
  let fill;
  switch (newChartConfig.trackingProp) {
    case ctFields.water:
      propName = ctFields.water;
      label = ctFieldTitles[ctFields.water];
      fill = '+1';
      break;
    case ctFields.axialNoise:
      propName = 'noise';
      label = ctFieldTitles[ctFields.axialNoise];
      fill = true;
      newChartConfig.filter = 'mode=axial';
      break;
    case ctFields.helicalNoise:
      propName = 'noise';
      label = ctFieldTitles[ctFields.helicalNoise];
      fill = true;
      newChartConfig.filter = 'mode=helical';
      break;
    case mriFields.centerFreq:
      propName = mriFields.centerFreq;
      label = mriFieldTitles[mriFields.centerFreq];
      fill = '+1';
      break;
    case mriFields.txGainAtten:
      propName = mriFields.txGainAtten;
      label = mriFieldTitles[mriFields.txGainAtten];
      fill = '+1';
      break;
    case mriFields.geoAccuracyHf:
      propName = mriFields.geoAccuracyHf;
      label = mriFieldTitles[mriFields.geoAccuracyHf];
      fill = '+1';
      break;
    case mriFields.geoAccuracyAp:
      propName = mriFields.geoAccuracyAp;
      label = mriFieldTitles[mriFields.geoAccuracyAp];
      fill = '+1';
      break;
    case mriFields.geoAccuracyRl:
      propName = mriFields.geoAccuracyRl;
      label = mriFieldTitles[mriFields.geoAccuracyRl];
      fill = '+1';
      break;
    default:
      newChartConfig.datasetConfigs = {};
  }

  if (newValues.showRange) {
    newChartConfig.datasetConfigs[`${propName}_over`] = {
      label: `${label} over range`,
      ...outValuesDatasetConfig,
      fill: '+1',
    };
    newChartConfig.datasetConfigs[`${propName}_max`] = {
      label: `${label} max`,
      ...maxDatasetConfig,
      fill,
    };
    newChartConfig.datasetConfigs[`${propName}_min`] = {
      label: `${label} min`,
      ...minDatasetConfig,
    };
    newChartConfig.datasetConfigs[`${propName}_below`] = {
      label: `${label} below range`,
      ...outValuesDatasetConfig,
      fill: '-1',
    };
  }
  newChartConfig.datasetConfigs[`${propName}_value`] = {
    label,
  };

  return newChartConfig;
};
