/* eslint-disable jsx-a11y/click-events-have-key-events */
//@ts-ignore
//@ts-nocheck

import React from 'react';
import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import { formatDate } from 'utils/time.util';
import { formatIntoFirstLastInitial } from 'utils/user.util';
import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from '@tanstack/react-table';

import TablePaginationActions from '../../components/TablePaginationActions';
import { useDeviceCtx } from 'context/DeviceContext';
import { getDeviceStatus } from 'utils/device.util';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeviceRowAction from './DeviceRowAction';
import { useHistory } from 'react-router-dom';

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])}
        placeholder="Max"
        className="w-24 border shadow rounded"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  ) : (
    <InputBase
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      sx={{ fontSize: 12, border: '1px solid #f1f0f0', borderRadius: '4px', paddingLeft: '2px ' }}
      className="w-36 border shadow rounded"
      inputProps={{ 'aria-label': 'search' }}
    />
  );
}

function DevicesTable({ devices, columns }: { devices: any[]; columns: ColumnDef<any>[] }) {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'facilityName',
      desc: false,
    },
  ]);
  const history = useHistory();
  const table = useReactTable({
    data: devices,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
  });

  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <Box width="100%">
      <TableContainer component={Box} sx={{ marginTop: '16px' }}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header) => {
                  return (
                    <TableCell sx={{ fontSize: 14, fontWeight: 600 }} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ArrowDropUpIcon sx={{ fontSize: '16px' }} />,
                            desc: <ArrowDropDownIcon sx={{ fontSize: '16px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <Box marginTop="4px">
                          <Filter column={header.column} table={table} />
                        </Box>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel()?.rows?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FAFAFA',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => history.push(`/devices/${row?.original._id}`)}
                >
                  {row.getVisibleCells()?.map((cell) => {
                    return (
                      <TableCell sx={{ fontSize: 14 }} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: devices.length }]}
        component="div"
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={(_, page) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
}

function DevicesTableContainer() {
  const { devices } = useDeviceCtx();
  const setStatus = (device) => getDeviceStatus(device);
  const setColorByStatus = (status) => {
    switch (status) {
      case 'Out of Spec':
        return '#ff0000';
      case 'Past Due Review':
        return '#c6823f';
      case 'Past Due QA':
        return '#d9a937';
      case 'OK':
        return '#28b0aa';
      default:
        return '#000';
    }
  };

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row) => row.facilityName,
        id: 'facilityName',
        cell: (info) => info.getValue(),
        header: () => <span>Facility</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'name',
        id: 'name',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'type',
        header: () => 'Type',
        cell: (info) => <Typography sx={{ textTransform: 'uppercase', fontSize: 14 }}>{info.getValue()}</Typography>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'outFields',
        header: () => 'Status',
        accessorFn: (row) => setStatus(row),
        cell: (info) => (
          <Chip
            size="small"
            sx={{
              fontSize: 12,
              borderRadius: '4px',
              borderColor: setColorByStatus(setStatus(info.row.original)),
              color: setColorByStatus(setStatus(info.row.original)),
            }}
            label={setStatus(info.row.original)}
            variant="outlined"
          />
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'lastQaDate',
        header: () => <span>Last Validated On</span>,
        accessorFn: (row) => formatDate(row.actions.length > 0 ? row.actions[0].date : null),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'lastQaBy',
        header: 'By',
        accessorFn: (row) => formatIntoFirstLastInitial(row?.lastQaBy),
        footer: (props) => props.column.id,
      },
      {
        id: '-',
        header: '',
        cell: (info) => <DeviceRowAction device={info.row.original} />,
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  return <DevicesTable {...{ devices, columns }} />;
}

export default DevicesTableContainer;
